#page-container {
  position: relative;
  min-height: 100vh;
}

h1 {
  text-align: left;
  padding-left: 25px;
}

h2 {
  text-align: left;
  padding-left: 25px;
}

h3 {
  text-align: left;
  padding-left: 25px;
}

h4 {
  text-align: left;
  padding-left: 25px;
}


form {
  text-align: left;
  padding-left: 25px;
  background-color: white;
  display: block;
  justify-content: center;
  color: red;
  font-size: 10px;
}

form small {
  color: black;
  font-size: 12px;
}

p {
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
  background-color: white;
  color: black;
}

#upper {
  padding-bottom: 3rem;
}

#footer {
  position: absolute;
  height: 3rem;
  bottom: 5px;
}

button {
  max-width: 10vh;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #111;
}


.dropbtn {
 background-color: #333;
 color: white;
 text-align: center;
 max-width: 100vh;
 padding: 14px 16px;
 font-size: 16px;
 border: none;
}

.dropdown {
 display: block;
 overflow: hidden;
}

.dropdown-content {
 display: none;
 position: absolute;
 background-color: #f1f1f1;
 box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
 z-index: 1;
}

.dropdown-content a {
 color: black;
 padding: 12px 16px;
 text-align: left;
 text-decoration: none;
 display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #111;
}

table {
 padding-left: 10px;
 text-align: left;
}

th, td {
 padding-left: 15px;
}

#nested {
  padding-left: 0px;
}
